@import './normalize.css';

@font-face {
  font-family: "TahomaRegular";
  src: local("TahomaRegular"),
    url("./fonts/Tahoma Regular font.ttf") format("truetype");
  font-weight: 400;
}
/* 
  PostCSS una herramienta para transformar CSS con plugins de Javascript 
  Aqui estamos utilizando PostCss?, @tailwind es un plugin?, debo tener Vscode extension para postCss? si
*/
@tailwind base;
@tailwind components;
@tailwind utilities;